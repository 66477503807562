// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBAhT2e0VRRcF-PpN-XIUDjzMy0_idzr8Q",
    authDomain: "myyachtlive2-6529c.firebaseapp.com",
    projectId: "myyachtlive2-6529c",
    storageBucket: "myyachtlive2-6529c.appspot.com",
    messagingSenderId: "121983523674",
    appId: "1:121983523674:web:149082d469a16269c23163",
    measurementId: "G-S4PLZV9SN7",
    vapidKey:"BML9Nnz8hCL-PA_dPsNRBNuQJVqvQw2Z8-aeolMD6V5e3VTHdorWjUZfEaKl_cUSc53vkYy42k3OfU-6-PaNq50"
    // apiKey: "AIzaSyD1G2LUzKJMvF4NXubsZN8koJ64xBdsh-U",
    // authDomain: "dev-myyachtlive.firebaseapp.com",
    // databaseURL: "https://dev-myyachtlive.firebaseio.com",
    // projectId: "dev-myyachtlive",
    // storageBucket: "dev-myyachtlive.appspot.com",
    // messagingSenderId: "937423403990",
    // appId: "1:937423403990:web:b8dbac6eb5c4274ca18277",
    // measurementId: "G-Y13YDKGM65"
  },
  openWeatherKey: '6391a00d8da4f6172d1855f2822a0937',
  googleMaps: 'AIzaSyBTrDQ5Ru0Ri9oQRFnWppXYtFTzy6NPfVk',
  version: '2.0.0',
  lastDeployment: new Date(),
  name: 'development'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
