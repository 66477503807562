import { Injectable } from '@angular/core';
import { Auth, signInWithEmailAndPassword, getAuth, sendEmailVerification, signOut, createUserWithEmailAndPassword } from '@angular/fire/auth';
import { Functions, getFunctions, httpsCallable } from '@angular/fire/functions';
import { MessagingService } from '../messaging/messaging.service';
import { BehaviorSubject } from 'rxjs';
import { IAuth } from './auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _loggedInUser: BehaviorSubject<IAuth> = new BehaviorSubject(null);
  private _auth: Auth;
  private _fun: Functions;

  constructor(private _fireAuth: Auth, private messageService: MessagingService) {
    this._auth = getAuth(this._fireAuth.app);
    this._fun = getFunctions(this._fireAuth.app,'us-east1');
  }

  public readonly loggedInUser: BehaviorSubject<IAuth> = this._loggedInUser;

  public isValidated(): boolean {
    const currentUser = this._auth.currentUser
    return currentUser.emailVerified
  }

  async createUser(userData: any) {
    return createUserWithEmailAndPassword(this._auth, userData.username, userData.password).then(async (response: any) => {
      const currentUser = this._auth.currentUser
      sendEmailVerification(currentUser);
      const tmp = await this.getDeviceInfo(null, null, false)
      const user = {
        deviceInfo: tmp.deviceInfo,
        deviceLocation: tmp.deviceLocation,
        email: currentUser.email,
        firstName: userData.firstName,
        imei: "",
        ipInfo: tmp.ipInfo,
        lastName: userData.lastName,
        uid: currentUser.uid,
      }
      this.persistUser(user) /// sends data to firestore
      .then((result: any) => { 
        this.login(userData.username, userData.password);
      })
      .catch((e:any) => console.error('action.persistUser', 'Error persisting User in MyYachtLive with code: ' + e.code + ' and message: ' +  e.message));
      return "ok";
    }, (error) => {
      console.log(error.message)
      return error.e;
    })
  }

  async login(email: string, password: string): Promise<string> {
      return signInWithEmailAndPassword(this._auth, email, password).then(async (response: any) => {
        
        if(response.user) {
          const claims = (await response.user.getIdTokenResult(true)).claims;
          const uid = (await response.user).uid;
          const validated = await response.user.emailVerified;
          const session = await response.user.session;
          if (validated) {
            const session = Math.random().toString(16).substring(2, 10);
            this.setUser(claims, uid, session, true);
            this.messageService.requestPermission(uid, session);
          return "ok";}
        } else {
          console.log(response)
          return "error";
        }
      }, (error) => {
        return error.e;
      })
  }

  async logout() {
    this.setUser(null, null, null, false)
  }

  async setUser(claims: any, uid: string, session: string, login=true) {
    let user: any;
    if (login) {
      user = await this.getDeviceInfo(claims, uid, login);

      this._loggedInUser.next({...user, session});
      this.persistFunLogInOut({
          origin: location.origin,
          session,
          uid,
          claims: claims?.u || null,
          deviceInfo: user.deviceInfo,
          deviceLocation: user.deviceLocation,
          ipInfo: null,
          login
        });
    } else {
      user = {
        origin: null,
        session: null,
        uid,
        claims: null,
        deviceInfo: null,
        deviceLocation: null,
        ipInfo: null,
        login
      }
      if (this._loggedInUser.value) {
        await this.persistFunLogInOut({
          login: false,
          session: this._loggedInUser.value.session,
          uid: this._loggedInUser.value.uid
        });
        await signOut(this._auth);
      }
      this._loggedInUser.next(user);
    }
  }

  private async getDeviceInfo(claims: any, uid: string, login:boolean): Promise<any> {
    const deviceInfo = {
      maxTouchPoints: navigator.maxTouchPoints,
      hardwareConcurrency: navigator.hardwareConcurrency,
      cookieEnabled: navigator.cookieEnabled,
      userAgent: navigator.userAgent,
      language: navigator.language,
      languages: navigator.languages
    };

    let deviceLocation: any = null;
    await navigator.geolocation.getCurrentPosition(
      response => {
        deviceLocation = {
          lat: response.coords.latitude,
          lng: response.coords.longitude,
          speed: response.coords.speed,
          heading: response.coords.heading,
          altitude: response.coords.altitude,
          accuracy: response.coords.accuracy,
          altitudeAccuracy: response.coords.altitudeAccuracy,
          timeStamp: new Date(response.timestamp)
        };
      },
      response => {
        deviceLocation = {
          error: response.message
        };
      }
    );

    let user: IAuth = {
      origin: location.origin,
      session: null,
      uid,
      claims: claims,
      deviceInfo,
      deviceLocation,
      ipInfo: null,
      login
    }

    return user;
  }

  private async persistFunLogInOut(user:any) {
    const persistLogInOut = httpsCallable(this._fun,'persistLogInOut');
    await persistLogInOut({...user}).catch((e) => {
      console.log(e)
    })
  }

  private async persistUser(user:any) {
    const persistUser = httpsCallable(this._fun,'persistUser');
    await persistUser({...user}).catch((e) => {
      console.log(e)
    })
  }
}
