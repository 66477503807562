import {IBoat, IWind, ISignalKformat, INavigation, IEnvironment, IPosition, IDepth} from './data.interfaces';

// tslint:disable: no-use-before-declare
export class Boat implements IBoat{
    created = new Date('1900-01-01');
    source = null;
    self = {
        navigation: new Navigation(),
        environment: new Environment(),
        other: {
            ain1: null,
            ain2: null
        }
    };
}

export class Navigation implements INavigation{
    position = new Position();
    courseOverGroundTrue = new SignalKformat();
    headingTrue = new SignalKformat();
    headingMagnetic = new SignalKformat();
    // speedOverGround?: number;
    // speedThroughWater?: number;
    // courseOverGroundMagnetic?: number;
    // attitude?: Attitude;
}

export class Environment implements IEnvironment{
    wind = new Wind();
    depth = new Depth();
    // outside?: Outside;
}

export class Position implements IPosition{
    value = {
        latitude: null,
        longitude: null
    };
    timestamp = null;
    $source = null;
    sentence = null;
}

export class Wind implements IWind {
    speedApparent = new SignalKformat();
    angleApparent = new SignalKformat();
    speedTrue = new SignalKformat();
    angleTrueGround = new SignalKformat();
}

export class Depth implements IDepth{
    belowTransducer = new SignalKformat();
}

export class SignalKformat implements ISignalKformat{
    value = null;
    timestamp = null;
    $source = null;
    sentence = null;
}
