import { Injectable, OnDestroy } from '@angular/core';
import { IConnectToDataSource } from './IConnectToDataSource';
import { HttpClient } from '@angular/common/http';
import { map, skip } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBoat } from './data.interfaces';
import { Boat } from './data.initialValue';

@Injectable(
  {
	providedIn: 'root'
}
)
export class DemoFile implements IConnectToDataSource, OnDestroy {
	private _fileTimer = null;
  private _subHttp: any;
  private _subject = new BehaviorSubject<IBoat>(new Boat())

	constructor(private http: HttpClient) {}
  private _sampleIndex = 0;
  private _data;

	ngOnDestroy() {
    this.clean();
	}

	connectTo(): BehaviorSubject<IBoat> {
    this._subHttp = this.http.get('assets/demoFile.json', {responseType: 'text'})
    .pipe(
      map(data => JSON.parse(data))
    ).subscribe((res) => {
        this._data = res;
        this._subject.next(res[this._sampleIndex++]);
        this._fileTimer = setInterval(() => {
          let boatData:IBoat = res[this._sampleIndex++] as IBoat;
          boatData.source = "Demo";
          boatData.created = new Date(boatData.created)
          this._subject.next(boatData);
          if (this._sampleIndex === res.length) {this._sampleIndex = 0}
        }, 2000);
      });
      return this._subject;
	}

	clean(): void {
    if (this._subHttp) {this._subHttp.unsubscribe()}
		if (this._fileTimer) {
			clearInterval(this._fileTimer);
			this._fileTimer = null;
		}
	}

  last() {
    if (!!this._data && this._data.length > 0){
      let boatData:IBoat 
      if (this._sampleIndex < this._data.length-1) {
        boatData = this._data[this._sampleIndex++] as IBoat;
      } else {
        boatData = this._data[0] as IBoat;
      }
      boatData.source = "Demo";
      boatData.created = new Date(boatData.created)
      this._subject.next(boatData);
    }
  }
}
