import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { IAuth } from '../auth/auth.interface';
import { AuthService } from '../auth/auth.service';
import { Boat } from './data.initialValue';
import { IBoat } from './data.interfaces';
import { DemoFile } from './DemoFile';
import { IConnectToDataSource } from './IConnectToDataSource';
import { MyYachtLiveServer } from './MyYachtLiveServer';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private _data: BehaviorSubject<IBoat> = new BehaviorSubject(new Boat());
  private _source: IConnectToDataSource
  private _lastPositions: BoatHistory<IBoat> = new BoatHistory(30);
  private _connectSub: Subscription = null;

  constructor(private demoFile: DemoFile,
              private myYachtLiveServer: MyYachtLiveServer,
              private _auth: AuthService) {
    
    this._auth.loggedInUser.pipe(
      skip(1),
      filter((result) => !!result)
    ).subscribe((loggedInUser:IAuth) => {
      if(this._connectSub) {
        this._connectSub.unsubscribe();
        this._connectSub = null;
      }
      if (!loggedInUser.uid) {
        this.myYachtLiveServer.clean();
        this._source = this.demoFile;
      } else {
        this.demoFile.clean();
        this._source = this.myYachtLiveServer;
      }
      this._source.last();
      this._connectSub = this._source.connectTo()
      .pipe(
        filter((res) => !!res.self.navigation.position.value.latitude && !!res.self.navigation.position.value.longitude)
      )
      .subscribe((res:IBoat) => {
        if (res.source)
        {this._data.next(res);
        this._lastPositions.add(res);}
      },(e) => {console.log('Error in dataService: ', e)})
    })
  }

  public readonly data: BehaviorSubject<IBoat> = this._data;

  getLast() {
    if (this._source) {
        this._source.last();
    }
  }

  getBoatHistory():IBoat[]  {
    return this._lastPositions.getHistory();
  }
}

class BoatHistory<Type> {
  private elements: Type[] = [];

  constructor(private maxLenght: number) {}
  
  add(element: Type) {
    if (JSON.stringify(element) != JSON.stringify(this.elements[this.elements.length-1])) {
      this.elements.push(element);
      if (this.elements.length > this.maxLenght) {
        this.elements.shift();
      }
    }
  }

  getHistory(): Type[] {
    return this.elements;
  }
}
