import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlarmState } from './alarmStateInitialValue';
import { IAlarmState } from './alarmStateInterface';

@Injectable({
  providedIn: 'root'
})
export class AlarmStateService {
  private _initialState = new AlarmState();
  private _alarmState: BehaviorSubject<IAlarmState> = new BehaviorSubject(this._initialState);
  constructor() { }

  public readonly alarmState: BehaviorSubject<IAlarmState> = this._alarmState;

  public newMessage(alarmState:IAlarmState) {
    console.log(alarmState)
    this._alarmState.next(alarmState)
  }
}
