import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LanguageService } from './services/language/language.service';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { getAuth, onAuthStateChanged, User } from '@angular/fire/auth';
import { DataService } from './services/data/data.service';
import { AuthService } from './services/auth/auth.service';
import { Analytics, getAnalytics, logEvent, setUserId} from '@angular/fire/analytics';
import { FirebaseApp, initializeApp} from '@angular/fire/app';
// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { environment } from '../environments/environment';
import { MessagingService } from './services/messaging/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private _analytics: Analytics;
  
  constructor(private platform: Platform,
              private language: LanguageService,
              private backgroundMode: BackgroundMode,
              private fireApp: FirebaseApp, // without it does not work even if fireApp is not used. I think it initializes firebase main object
              private fireAnalytics: Analytics,
              private dataService: DataService,
              private authService: AuthService,
              // private screenOrientation: ScreenOrientation,
              private messageService: MessagingService
              ) {
                console.log("34567890")
    const app = initializeApp(environment.firebase);
    const analytics = getAnalytics(app);
    this.initializeApp();
  }

  private initializeApp() {
    this.checkUser()
    this.platform.ready().then((source) => {
      console.log(source)
      console.log(this.platform.platforms())
      if (this.platform.is('android')) {
        // document.documentElement[this.getRequestFullScreenKey()]();
        // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        this._analytics = getAnalytics();
        logEvent(this._analytics, 'opened app');

        console.log('android')
        this.platform.pause.subscribe(async () => {
          console.log("Pause event detected");
          logEvent(this._analytics, 'pause event'); 
          //Do stuff here
        });
    
        this.platform.resume.subscribe(async () => {
          console.log("Resume event detected");
          logEvent(this._analytics, 'resume event'); 
          this.dataService.getLast();
        });

        this.backgroundMode.on('enable').subscribe(() => {
          console.log('.... enabled')
          // this.backgroundMode.disableWebViewOptimizations(); // does not work
          this.backgroundMode.disableBatteryOptimizations();
        });
        this.backgroundMode.on('activate').subscribe(() => {
          console.log('.... activate -----------------------')
          // this.backgroundMode.disableWebViewOptimizations();
          this.backgroundMode.disableBatteryOptimizations();
        });
        this.backgroundMode.on('failure').subscribe(() => {
          console.log('failed')
        })
        this.backgroundMode.enable();
        this.backgroundMode.setEnabled(true);
      
        // suggested to keep the app running in background however it is annoying and I did not see any difference
        // setInterval(() => {
        //   // const a = this.backgroundMode.wakeUp();
        // }, 30000);
      }
      // window.screen.orientation.lock('portrait');
      this.language.setInitialLanguage();
    })
  }

  private getRequestFullScreenKey() {
        let goFullScreen = 'requestFullscreen';
        if ('mozRequestFullScreen' in document.documentElement) {
            goFullScreen = 'mozRequestFullScreen';
        } else if ('webkitRequestFullscreen' in document.documentElement) {
            goFullScreen = 'webkitRequestFullscreen';
        } else if ('msRequestFullscreen' in document.documentElement) {
            goFullScreen = 'msRequestFullscreen';
        }
        return goFullScreen;
    }

  private async checkUser() {
    const auth = getAuth();
    const sub = onAuthStateChanged(auth, async (user:User) => {
      if (user) {
        this.messageService.requestPermission(null,null);
        const claims = (await user.getIdTokenResult(true)).claims;
        const uid = user.uid;
        if (this.platform.is('android')) {
          setUserId(this._analytics, uid);
          logEvent(this._analytics, 'signed in'); 
        }
        this.authService.setUser(claims, uid, null, true); // user login
        sub();
      } else {
        if (this.platform.is('android')) {
          setUserId(this._analytics, 'DEMO');
          logEvent(this._analytics, 'signed in'); 
        }
        this.authService.setUser(null, null, null, false); // Demo mode 
        sub();
      }
    })
  }
}
