import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth} from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideAnalytics,getAnalytics} from '@angular/fire/analytics';
import { provideMessaging,getMessaging} from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

export function createTranslateLoader(http:HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, 
            IonicModule.forRoot({}), 
            AppRoutingModule,
            FormsModule,
            HttpClientModule,
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
              }
            }),
            provideFirebaseApp(() => initializeApp(environment.firebase)),
            provideAuth(() => getAuth()),
            provideFirestore(() => getFirestore()),
            provideFunctions(() => getFunctions()),
            provideAnalytics(() => getAnalytics()),
            provideMessaging(() => getMessaging()),
            ServiceWorkerModule.register('combined-sw.js', {
              enabled: !isDevMode(),
              // Register the ServiceWorker as soon as the application is stable
              // or after 30 seconds (whichever comes first).
              registrationStrategy: 'registerWhenStable:30000'
            })],
  providers: [{ provide: RouteReuseStrategy, 
                useClass: IonicRouteStrategy
              },
              BackgroundMode,
              // ScreenOrientation
            ],
  bootstrap: [AppComponent],
})
export class AppModule {}
