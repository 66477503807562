import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule)},
  {
    path: 'map-share',
    loadChildren: () => import('./pages/map-share/map-share.module').then( m => m.MapSharePageModule)
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'prefix'
  },
  {
    path: 'row-data',
    loadChildren: () => import('./pages/fromMenu/row-data/row-data.module').then( m => m.RowDataPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/fromMenu/signin/signin.module').then( m => m.SigninPageModule)
  },
  // {
  //   path: 'alarm-message',
  //   loadChildren: () => import('./pages/fromMenu/alarm-message/alarm-message.module').then( m => m.AlarmMessagePageModule)
  // },
  // {
  //   path: 'battery',
  //   loadChildren: () => import('./pages/fromMenu/battery/battery.module').then( m => m.BatteryPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
