import { Injectable, OnDestroy } from '@angular/core';
import { IConnectToDataSource } from './IConnectToDataSource';
import { BehaviorSubject } from 'rxjs';
import { getAuth, Unsubscribe } from '@angular/fire/auth';
import { getFirestore, collection, query, where, onSnapshot, limit, orderBy, QuerySnapshot, getDocs } from '@angular/fire/firestore';
import { Boat } from './data.initialValue';
import { IBoat } from './data.interfaces';

@Injectable({
	providedIn: 'root'
})
export class MyYachtLiveServer implements IConnectToDataSource, OnDestroy {
  private _snapSub: Unsubscribe;
  private _subject = new BehaviorSubject<IBoat>(new Boat())
  private _lastCreated = null;
  private _lastPosition = null;
	constructor() {}

	ngOnDestroy() {
    this.clean();
	}
  // https://firebase.google.com/docs/firestore/query-data/listen

  connectTo(): BehaviorSubject<IBoat> {
    const auth = getAuth()
    const user = auth.currentUser
    const fireStore = getFirestore()
    const q = query(collection(fireStore, "boats"), where("uid", "==", user.uid), orderBy('created', 'desc'), limit(1));
    this._snapSub = onSnapshot(q, (querySnapshot) => {
      const results = [];
      querySnapshot.forEach((element: any) => {
        results.push(element.data())
      });
      if (results.length > 0) {
        let boatData = this.decodeBoatData(results[0]);
        if (boatData?.self?.navigation?.position?.value != undefined)
        {
          this._lastCreated = results[0].created;
          this._lastPosition = boatData.self.navigation.position.value;
          this._subject.next(boatData); 
        }
      }
    },(e) => {console.log('Error in MYLServer: ', e)});
    return this._subject;
  }

  private decodeBoatData(rowData: any): IBoat {
      let boatData:IBoat
      // console.log(rowData)
      if (rowData.type) {
        if (rowData.type == 2){
          /// Support type 2 senders - e.g. FMB641
          boatData = {
            created: rowData.created.toDate(),
            source : "MYS2",
            session: rowData.session,
            uid: rowData.uid,
            self: {
              navigation: {
                position: {
                  value: {
                    latitude: rowData.lat,
                    longitude: rowData.lon
                  }
                },
                  courseOverGroundTrue: {value: rowData.cog},
                  headingTrue: {value: rowData.heading}, // to verify
                  headingMagnetic: {value: rowData.heading}, // to verify
              },
              environment: {
                wind: {
                  speedApparent: {value: rowData.aws},
                  angleApparent: {value: rowData.awa}
                },
                depth: {
                  belowTransducer: {value: rowData.depth}
                }
              },
              other: {
                ain1: rowData.ain1,
                ain2: rowData.ain2
              }
            }

          }
          // console.log(boatData)

        } else {
          console.log("error format receiving data")
        }
      } else {
        // Support for signalk plugin
        boatData = rowData;
        boatData.created = rowData.created.toDate()
        boatData.source = "MYS";
      }
      return boatData;
  }

	clean(): void {
    if (this._snapSub){
      this._snapSub();
      this._snapSub = null;
    }
	}

  async last() {
    if (!this._lastCreated){
      this._lastCreated = new Date();
      this._lastCreated.setHours(this._lastCreated.getHours() - 10);
    }
    const auth = getAuth()
    const user = auth.currentUser
    const fireStore = getFirestore()
    const q = query(collection(fireStore, "boats"), where("uid", "==", user.uid), where("created", ">", this._lastCreated), orderBy('created', 'desc'), limit(30));
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((element: any) => {
      results.push(element.data())
    });
    results.reverse().forEach((data) => {
      let boatData = this.decodeBoatData(data);
      // let boatData:IBoat = data;
      // boatData.created = new Date((data as IBoat).created);  //self.navigation.position.timestamp)
      // console.log(boatData.created)
      // boatData.source = "MYS";
      if (!this._lastPosition || this.isClose(boatData.self.navigation.position.value))
      this._subject.next(boatData);
    })
  }

  private isClose(position): boolean {
    const lat1 = position.latitude;
    const lon1 = position.longitude;
    const lat2 = this._lastPosition.latitude;
    const lon2 = this._lastPosition.longitude;
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
    
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    
    const d = R * c; // in metres
    return d > 1 && d<100
  }
}
