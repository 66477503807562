import { Injectable } from '@angular/core';
import { Functions, getFunctions, httpsCallable } from '@angular/fire/functions';
import { getApp } from '@angular/fire/app';
import { Messaging, getMessaging, getToken, onMessage, deleteToken, isSupported } from '@angular/fire/messaging';
import { environment } from '../../../environments/environment';
import { AlarmStateService } from '../alarmState/alarm-state.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private token = null;
  private afMessaging: Messaging;
  private _fun: Functions;

  constructor(private _fireFun: Functions, 
              private _fireMessaging: Messaging,
              private alarmStateService: AlarmStateService) {

    // if (isSupported) {
    //   console.log('===================')
      this.afMessaging = getMessaging(getApp());
    // }
    this._fun = getFunctions(this._fireFun.app,'us-east1');
  }

  public requestPermission(uid:string, session:string) {

    Notification.requestPermission().then((permission) => {
      if (permission === "granted" && isSupported) {
        console.log("granted permission")
        getToken(this.afMessaging,{vapidKey:environment.firebase.vapidKey}).then(token => {
          console.log('Store token to server: ', token);
          this.token = token;
          this.getMessages(); /// only for debug
          if (uid && session){
            this.persistToken(token, uid, session);
          }
        }, error => {
          console.log("error during notification permission ", error.code, error.e, error.message)
        })
      } else {
        console.log("permission not granted")
      }
    })
  }

  getMessages() {
    if (isSupported){
      onMessage(this.afMessaging,(payload: any) => {
        this.alarmStateService.newMessage(payload.notification);
        // console.log("message received from app: ", payload)
      });
    }
  }

  deleteToken() {
    if (this.token && isSupported) {
      deleteToken(this.afMessaging).then(this.token);
      this.token = null;
    }
  }

  private async persistToken(pushToken:string, uid: string, session:string) {
    const persistToken = httpsCallable(this._fun,'persistToken');
    await persistToken({pushToken, uid, session}).catch((e) => {
      console.log(e)
    })
  }
}